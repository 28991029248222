.games-local-page-wrapper {

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow:
    hidden; max-width: 100%;
  }

  .embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  section {
    // background-color: yellow;
    padding-left: calc(100% - 1160px);
    padding-right: 55px;
    margin-left: 18px;
    width: calc(100% - 18px);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    a {
      color: $yellow;
    }

    .wrapper {

    }
    .one {
      float: left;
      // background-color: blue;
      width: 50%;
      padding-right: 20px;
      padding-top: 40px;

      .one-top {
        // background-color: blue;
      }

      .one-bottom {
        display: inline-block;
        // background-color: red;

        @media screen and ( max-width: $mobile-breakpoint ) {
          margin-top: 40px;
        }
        // height: 200px;
          .one-bottom-left {
            float:left;
            width: 40%;
            // background-color: green;
            padding-right: 20px;
            // min-width: 130px;
          }
          .one-bottom-right {
            float:left;
            width: 60%;
            // background-color: yellow;
          }
          .store-wrapper {
            padding-top: 20px;

            a {
              // background-color: black;
              display: inline-block;
              width: 100%;
              margin-bottom: 20px;
            }

            .humble {
              width: 100%;
              max-width: 177px;
            }
            .psn {
              width: 100%;
              max-width: 108px;
            }
            .wii {
              width: 100%;
              max-width: 84px;
            }
            .steam {
              width: 100%;
              max-width: 81px;
            }
            .xbox {
              width: 100%;
              max-width: 93px;
            }
          }
      }
    }

    .two {
      float: left;
      // background-color: red;
      width: 50%;
      padding-top: 40px;
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 424px);
      
    }
  }

  @media screen and ( max-width: $mobile-breakpoint ) {
    section {
      padding-left: 0px !important;
      padding-right: 20px !important;
    }
  }

  @media screen and ( max-width: $mobile-small-breakpoint ) {
    section {
      .one, .two {
        float: none;
        width: 100%;

        img {
          max-width: 350px;
          width: 100%;
        }
      }
    }

  }
}
