.about-page-wrapper {

  section {
    position: relative;
    width: 100%;
    display: inline-block;
    // background-color: blue !important;
    // padding-left: 120px;
    padding-left: calc(100% - 1160px);
    padding-bottom: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;


    div {
      float: left;
    }

    .one {
      max-width: 440px;
      width: 50%;
      margin-right: 20px;
      padding-left: 18px;
      // background-color: red;
    }

    .wrapper {
      width: calc(100% - 355px);
      // background-color: tomato;
      float: left;


    }

    .two {
      // background-color: green;
      width: calc(50% - 20px);
      padding-right: 20px;
      span {
        text-decoration: underline;
      }
    }

    .three {
      float: left;
      // background-color: lime;
      width: 355px;
      // width: 49%;
      padding-left: 4px;
      a {
        color: $yellow;
      }
    }

    @media screen and ( max-width: $mobile-breakpoint ) {
      .wrapper {
        width: 100% !important;
      }
      .one {
        float: none;
        width: 100%;
        // max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
      .two {
       width: 100%;
       padding:20px;

      }
      .three {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }

    }


  }
}
