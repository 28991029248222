html, body {
  background-color: $black;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  font-weight: 700;
  font-size: 14px;
  color: $white;
  background-color: $black;
  font-family: 'Source Code Pro', monospace;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

.page-wrapper {
  text-align: center;
}


nav {
    z-index: 2000;
    position: fixed;
    display: inline-block;
    text-align: center;
    font-family: 'Source Code Pro', monospace;
    font-size: 16px;
    font-weight: 700;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: $black;
    // background-color: red;
    color: $white;

    .desktop-menu-wrapper {
      max-width: $global-max-width;
      .right-nav-wrapper {
        float: right;
        // background-color: red;
        margin-top: 15px;
        margin-right: 60px;

      }
      @media screen and ( max-width: $mobile-breakpoint ) {
        display: none !important;
      }
    }

        .menu-wrapper {
            display: inline-block;
            text-align: left;
            // background-color: yellow;
            width: 100%;
            max-width: 1280px;

            @media screen and ( max-width: $mobile-breakpoint ) {

              .desktop-menu-wrapper {
                display: none;
              }
            }

            .menu-button-first {
              display: inline-block;
              // background-color: red;
              padding: 20px 10px 20px 0px !important;
              img {
                width: 210px
              }
            }

            .menu-button {
                position: relative;
                display: block;
                text-align: left;
                float: left;
                text-transform: uppercase;
                padding: 20px 35px 20px 35px;
                letter-spacing: $body-text-letter-spacing;
                text-decoration: none;
                color: $white;
                font-weight: 700 !important;
            }

            .menu-button-extra {
              padding: 20px 12px 20px 33px !important;
            }

            .menu-button-about {
              padding-right: 20px !important;
            }

            .menu-button-thoth {
              padding-right: 28px !important;
            }

            .menu-button-140 {
              padding-right: 40px !important;
            }


            .menu-button:hover {
                cursor: pointer;
                background-color: $black;
                /* IE 8 */
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

                /* IE 5-7 */
                filter: alpha(opacity=50);

                /* Netscape */
                -moz-opacity: 0.5;

                /* Safari 1.x */
                -khtml-opacity: 0.5;

                /* Good browsers */
                opacity: 0.5;
            }

            $burger-menu-width: 200px;
            .burger-menu-wrapper {
              position: relative;

              .burgermenu-icon-start {
                position: absolute;
                top: 2px;
                right: 0px;
              }
              .burgermenu-icon-start:hover {
                cursor: pointer;
              }

              .burgermenu-icon-exit {
                position: absolute;
                top: 2px;
                right: 0px;
              }

              .burgermenu-icon-exit:hover {
                cursor: pointer;
              }


              @media screen and ( min-width: $mobile-breakpoint ) {
                display: none !important;
              }

              .logo {
                width: 250px;
                margin-left: -3px;
              }


              @media screen and ( max-width: $mobile-breakpoint ) {
                display: block;

                .burgermenu-icon-exit {
                  display:none;
                }

                i {
                  margin-top: 15px;
                  margin-bottom: 10px;
                  float: right;
                  margin-right: 0px;
                }

                i:hover {
                  cursor: pointer;
                  color: $white;
                }

                .burger-menu-content-wrapper {
                  display: none;
                  margin-top: 46px;
                  position: fixed;
                  right: -$burger-menu-width;
                  top: 0px;
                  width: 200px;
                  height: 100vh;

                }

              }
            }

            .display-burger-menu {

              @media screen and ( min-width: $mobile-breakpoint ) {
                display: none !important;
              }

              i {

              }

              .burger-menu-content-wrapper {
                // // display: none;
                display: inline-block !important;
                position: fixed;
                right: 0px;
                top: 24px;
                width: 320px;
                text-align: left;
                padding-left: 20px;
                padding-top: 20px;
                height: calc(100vh - 46px);
                background-color: $white;

                a {

                  color: $black;

                }

                li {
                  text-transform: uppercase;
                  margin-bottom: 20px;
                }
              }
            }
        }

        @media screen and ( max-width: $mobile-breakpoint ) {
          // background-color: red;
        }
}



main {

    z-index: 1500;
    position: relative;
    display: inline-block;
    text-align: center;
    padding-top: 100px;
    width: 100%;
    // background-color: blue;

    .main-content-wrapper  {
      display: inline-block;
      width: 100%;
      text-align: left;
      color:$white;
      // background-color: red;
      margin-bottom: 100px;
      max-width: $global-max-width;


      section {

      }
    }

    @media screen and ( max-width: $mobile-breakpoint ) {
      padding-top: 70px;
    }

}
