.contact-page-wrapper {

  section {
    position: relative;
    width: 100%;
    display: inline-block;
    // background-color: blue !important;
    padding-left: 120px;
    padding-bottom: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    .contact {
      float:right;
      // background-color: red;
      width: 352px;
      a {
        color: $yellow;
      }
    }


  }
  @media screen and ( max-width: $mobile-breakpoint ) {

    section {
      padding-left: 0px !important;
      padding-bottom: 50px;

      .contact {
        float: left;
        width: 100%;
        padding-left: 18px;
      }
    }




  }
}
